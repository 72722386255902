<template>
  <div class="row">

    <!-- Profile Card -->
    <div class="col-md-3">
      <Card title="Profile">
        <template v-slot:body>
          <div class="d-flex align-items-center">
            <div class="symbol symbol-60 symbol-xxl-100 mr-3 align-self-start align-self-xxl-center">
              <div style='height: 80px; width: 80px; position: relative'>
                <img
                  class="image"
                  :src="data.photo"
                >
              </div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ data.name }}</a>
              <div class="mt-2">
                <button
                  class="btn btn-sm btn-info font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                  @click="$router.push({ path: '/profile/show' })"
                >Info</button>
              </div>
            </div>
          </div>
          <div class="py-9">
            <div class="d-flex mb-2">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{ data.email }}</a>
            </div>
            <div class="d-flex mb-2">
              <span class="font-weight-bold mr-2">Phone:</span>
              <span class="text-muted">{{ data.phone }}</span>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Change Password Form -->
    <div class="col">
      <Card title="Form Edit Password">
        <template v-slot:body>
          <b-form @submit.stop.prevent="formOnSubmit">

            <!-- Old Password Input -->
            <div class="form-group">
              <label for="oldPassword">Password Lama:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="oldPasswordIsVisible == 'password' ? oldPasswordIsVisible = 'text' : oldPasswordIsVisible = 'password' "
                  >
                    <div v-if="oldPasswordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="oldPasswordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="oldPasswordIsVisible"
                  class="form-control"
                  placeholder="Password Lama"
                  v-model="form.old_password"
                  id="oldPassword"
                >
              </div>
              <small class="text-danger">{{ error.old_password }}</small>
            </div>

            <!-- New Password Input -->
            <div class="form-group">
              <label for="newPassword">Password Baru:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="passwordIsVisible == 'password' ? passwordIsVisible = 'text' : passwordIsVisible = 'password' "
                  >
                    <div v-if="passwordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="passwordIsVisible"
                  class="form-control"
                  placeholder="Password Baru"
                  v-model="form.new_password"
                  id="newPassword"
                >
              </div>
              <small class="text-danger">{{ error.new_password }}</small>
            </div>

            <!-- Password Confirmation Input -->
            <div class="form-group">
              <label for="passwordConfirmation">Konfirmasi Password:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="passwordConfirmIsVisible == 'password' ? passwordConfirmIsVisible = 'text' : passwordConfirmIsVisible = 'password' "
                  >
                    <div v-if="passwordConfirmIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordConfirmIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="passwordConfirmIsVisible"
                  class="form-control"
                  placeholder="Ketik Ulang Password"
                  v-model="form.new_password_confirmation"
                  id="passwordConfirmation"
                >
              </div>
              <small class="text-danger">{{ error.new_new_password_confirmation }}</small>
            </div>

            <!-- Submit & Cancel button -->
            <b-button
              type="submit"
              variant="primary"
            >Simpan</b-button>
            <b-button
              class="ml-2"
              variant="default"
              @click="$router.push('/profile/show')"
            >
              Cancel
            </b-button>

          </b-form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { getUser } from '@/core/services/jwt.service.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card
  },

  data() {
    return {
      // Data
      data: getUser(),
      // Form
      form: {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
        '_method': 'put'
      },
      // Error
      error: {
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
      },
      // Other
      oldPasswordIsVisible: 'password',
      passwordIsVisible: 'password',
      passwordConfirmIsVisible: 'password',
    }
  },

  methods: {

    async formOnSubmit() {
      // Make Request
      let response = await module.submit(this.form, 'profile/change-password/' + this.data.user_id)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/profile/show')
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile", route: "/profile/show" },
      { title: "Edit Password", route: "" },
    ])
  }

}
</script>

<style>
</style>